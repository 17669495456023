import { FC } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";

import {
  GRANTVESTMENT_STATUS,
  GrantVestment,
} from "../../types/grant-vestment";
import { getPercentage, moneyFormat } from "../../utils/money-format";
import { numberOfDays, shortFormat } from "../../utils/date-format";
import imagePlaceholader from "../../assets/image-placeholader.png";
import { neutral } from "style/color";
import { TRANSACTION_STATUS } from "types/contribution-transaction";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { useNavigate } from "react-router-dom";
import { MyGrantVestmentDetailsPage } from "constants/pages";
interface FundBackedGrantVestmentCardProps {
  grantvestment: GrantVestment;
  grantVestmentRequestStatue: TRANSACTION_STATUS;
  backedAmount: number;
  approvedAmount: number | undefined;
  pendingAmount: number | undefined;
}

const FundBackedGrantVestmentCard: FC<FundBackedGrantVestmentCardProps> = ({
  grantvestment,
  grantVestmentRequestStatue,
  backedAmount = 0,
  approvedAmount,
  pendingAmount,
}) => {
  const navigate = useNavigate();
  const funded = getPercentage(
    grantvestment?.raisedAmount,
    grantvestment?.goalAmount
  );
  const daysLeft =
    numberOfDays(grantvestment?.deadline) < 0
      ? 0
      : numberOfDays(grantvestment?.deadline);
  const handleClick = () => {
    const url = MyGrantVestmentDetailsPage.replace(":id", grantvestment.name);
    navigate(url, { state: { id: grantvestment.grantVestmentId } });
  };
  const getContent = () => {
    return (
      <>
        {" "}
        <CardContent sx={{ p: { xs: 2, md: 5 } }}>
          <Typography variant="subtitle1" gutterBottom sx={{ ml: 0.8 }}>
            {grantvestment.name}
          </Typography>
          <Typography
            variant="bodyCopyRegular"
            sx={{ color: "neutral.lighter", ml: 0.8 }}
            paragraph
          >
            {grantvestment.projectBy}
          </Typography>
          {grantvestment?.status === GRANTVESTMENT_STATUS.CLOSED ? (
            <Grid sx={{ alignItems: "center" }} container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="smallCopy"
                  color={"primary"}
                  sx={{ mb: 1 }}
                  className="title"
                  paragraph
                >
                  {funded}% funded
                </Typography>
                <Typography
                  variant="subtitle3"
                  sx={{ color: "neutral.main", mb: 1 }}
                  gutterBottom
                >
                  ${moneyFormat(grantvestment?.raisedAmount!)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ textAlign: { xs: "center", md: "right" } }}
              >
                <Typography
                  className="title"
                  variant="smallCopy"
                  sx={{ color: neutral[500], mb: 1 }}
                  paragraph
                >
                  current investment value
                </Typography>
                <Typography
                  variant="subtitle3"
                  sx={{ color: "neutral.main", mb: 1 }}
                  paragraph
                >
                  ${moneyFormat(backedAmount! * grantvestment?.moicValue!)}
                </Typography>
                <Typography
                  variant="smallCopyBold"
                  sx={{ color: "neutral.lighter", mb: 1 }}
                  paragraph
                >
                  +{grantvestment?.moicValue}x
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid sx={{ alignItems: "center" }} container spacing={1}>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="smallCopy"
                  color={"primary"}
                  sx={{ mb: 1, ml: 0.8 }}
                  className="title"
                  paragraph
                >
                  {funded}% funded
                </Typography>
                <Box sx={{ width: "100%", my: 2, mb: 1, ml: 0.8 }}>
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={parseFloat(funded) > 100 ? 100 : parseInt(funded)}
                  />
                </Box>
                <Typography
                  variant="smallCopy"
                  sx={{ color: neutral[600], mb: 1, ml: 0.8 }}
                  className="title"
                  paragraph
                >
                  {daysLeft} days to go - until{" "}
                  {shortFormat(grantvestment?.deadline)}
                </Typography>
                <Box sx={{ width: "100%", ml: 0 }}>
                  {approvedAmount && approvedAmount > 0 ? (
                    <>
                      <Typography
                        variant="smallCopy"
                        sx={{
                          color: neutral[500],
                          mb: 1,
                          fontWeight: "700",
                          display: "flex",
                          alignItems: "center",
                        }}
                        paragraph
                      >
                        <AccessTimeFilledIcon
                          fontSize="small"
                          sx={{ p: 0.4 }}
                        />
                        Pending process{" "}
                        <Typography
                          variant="smallCopyBold"
                          sx={{
                            color: neutral[500],
                            mb: 0,
                            ml: 1,
                            fontWeight: "700",
                          }}
                          paragraph
                        >
                          ${moneyFormat(approvedAmount!)}
                        </Typography>
                      </Typography>
                    </>
                  ) : null}
                </Box>
                <Box sx={{ width: "100%", my: 1, ml: 0 }}>
                  {pendingAmount && pendingAmount > 0 ? (
                    <>
                      <Typography
                        variant="smallCopy"
                        sx={{
                          color: neutral[500],
                          mb: 1,
                          fontWeight: "700",
                          display: "flex",
                          alignItems: "center",
                        }}
                        paragraph
                      >
                        <AccessTimeFilledIcon
                          fontSize="small"
                          sx={{ p: 0.4 }}
                        />
                        Pending acceptance{" "}
                        <Typography
                          variant="smallCopyBold"
                          sx={{
                            color: neutral[500],
                            mb: 0,
                            ml: 1,
                            fontWeight: "700",
                          }}
                          paragraph
                        >
                          ${moneyFormat(pendingAmount!)}
                        </Typography>
                      </Typography>
                    </>
                  ) : null}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                alignSelf="self-start"
                sx={{
                  textAlign: { xs: "center", md: "right" },
                }}
              >
                <>
                  <Typography
                    className="title"
                    variant="smallCopy"
                    sx={{ color: "neutral.main", mb: 1 }}
                    paragraph
                  >
                    Backed
                  </Typography>
                  <Typography
                    variant="subtitle3"
                    sx={{
                      color:
                        grantVestmentRequestStatue ===
                        TRANSACTION_STATUS.PENDING
                          ? neutral[500]
                          : "neutral.main",
                      mb: 1,
                    }}
                    paragraph
                  >
                    ${moneyFormat(backedAmount!)}
                  </Typography>
                </>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </>
    );
  };
  return (
    <Card
      onClick={handleClick}
      sx={{
        display: { xs: "block", sm: "block", md: "flex" },
        "&:hover": { cursor: "pointer" },
      }}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <CardMedia
            component="img"
            sx={{
              "&:hover": { cursor: "pointer" },
              objectFit: "fill",
              aspectRatio: "1.95/1",
              height: "100%",
            }}
            image={
              grantvestment.imageUrl
                ? grantvestment.imageUrl
                : imagePlaceholader
            }
            alt="Live from space album cover"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ m: { xs: 0 }, width: "100%" }}>{getContent()}</Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default FundBackedGrantVestmentCard;
