import {
  Typography,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import {
  useGetInvestmentQuery,
  useMakeInvestmentRequestMutation,
} from "hooks/use-services/investment.service";
import { FC, useContext, useEffect, useState } from "react";
import { neutral, primary, warm } from "style/color";
import { moneyFormat } from "utils/money-format";
import { ReactComponent as ArrowUpIcon } from "./../../../assets/Icons/arrow-up.svg";
import SouthEastIcon from "@mui/icons-material/SouthEast";

import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { NumericFormatCustom } from "components/shared/currency-input";
import ErrorIcon from "@mui/icons-material/Error";
import {
  INVESTMENT_REQUEST_INTEREST_ENUM,
  INVESTMENT_REQUEST_TYPE_ENUM,
} from "types/investment-request";
import { useGetFundInvestmentQuery } from "hooks/use-services/investment-request.service";
import { SelectedFundDropDown } from "components/shared/select-fund-dropdown";
import { useAddTransferMutation } from "hooks/use-services/transfer-transaction.service";
import {
  TRANSFER_TRANSACTION_TYPE_ENUM,
  ASSET_TYPE,
} from "types/transfer-transaction";
interface Props {
  ticker: string;
  type: INVESTMENT_REQUEST_TYPE_ENUM;
  handleSuccess: (
    name: string,
    amount: number,
    units: number,
    interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM
  ) => void;
}

export const InvestmentRequestEnterData: FC<Props> = ({
  ticker = "",
  handleSuccess,
  type,
}) => {
  const { mutate, isLoading } = useMakeInvestmentRequestMutation(ticker);
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const { data: selectedFundInvestment } = useGetFundInvestmentQuery(
    selectedAccount?.fundAccountId || 0,
    ticker!
  );
  const [enterUnits, setEnterUnits] = useState<boolean>(false);
  const [requestedAmount, setRequestedAmount] = useState<number>(0);
  const [requestedUnits, setRequestedUnits] = useState<number>(0);
  const { data: investment, isSuccess } = useGetInvestmentQuery({
    ticker: ticker,
  });
  const [selectedFund, setSelectedFundAccount] = useState<{
    fundId: number;
    fundName: string;
  }>();

  useEffect(() => {
    if (type === INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER) {
      setEnterUnits(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: makeTransfer, isLoading: isMakingTransferLoading } =
    useAddTransferMutation(selectedAccount?.fundAccountId!);

  const onConfirmTransfer = () => {
    makeTransfer(
      {
        receiverFundId: selectedFund?.fundId!,
        transferType: TRANSFER_TRANSACTION_TYPE_ENUM.INVESTMENTS,
        amount: requestedUnits,
        assetType: ASSET_TYPE.UNIT,
        ticker: ticker,
      },
      {
        onSuccess: () =>
          handleSuccess(
            selectedFund?.fundName!,
            requestedAmount,
            requestedUnits,
            INVESTMENT_REQUEST_INTEREST_ENUM.UNITS
          ),
      }
    );
  };

  const handleRequestedAmountChange = (value: number) => {
    setRequestedAmount(value);
    if (investment?.marketPrice) {
      const units = value / investment?.marketPrice;
      setRequestedUnits(units);
    }
  };
  const handleRequestedUnitsChange = (value: number) => {
    setRequestedUnits(value);
    if (investment?.marketPrice) {
      const amount = value * investment?.marketPrice;
      setRequestedAmount(amount);
    }
  };
  const checkBalance = () => {
    if (type === INVESTMENT_REQUEST_TYPE_ENUM.BUY) {
      return (
        selectedAccount?.availableBalance === 0 ||
        requestedAmount > selectedAccount?.availableBalance!
      );
    }
    if (type === INVESTMENT_REQUEST_TYPE_ENUM.SELL) {
      return (
        selectedFundInvestment?.totalApprovedUnits === 0 ||
        requestedUnits > selectedFundInvestment?.totalApprovedUnits!
      );
    }
    if (type === INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER) {
      return (
        selectedFundInvestment?.totalApprovedUnits === 0 ||
        requestedUnits > selectedFundInvestment?.totalApprovedUnits!
      );
    }
  };
  const confirm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const interestedIn = enterUnits
      ? INVESTMENT_REQUEST_INTEREST_ENUM.UNITS
      : INVESTMENT_REQUEST_INTEREST_ENUM.AMOUNT;
    mutate(
      {
        fundId: selectedAccount?.fundAccountId!,
        requestedAmount: requestedAmount,
        requestedUnits: requestedUnits,
        type: type,
        interestedIn: interestedIn,
      },
      {
        onSuccess: () =>
          handleSuccess(
            investment?.name!,
            requestedAmount,
            requestedUnits,
            interestedIn
          ),
      }
    );
  };

  return (
    <Box>
      {isSuccess && investment && (
        <>
          <Typography variant="subtitle2" color={neutral[900]} gutterBottom>
            {investment.ticker}
          </Typography>
          <Typography variant="h2" color={neutral[900]} gutterBottom>
            {investment.name}
          </Typography>
          <Typography variant="bodyCopyRegular" color={neutral[900]} paragraph>
            {investment.description}
          </Typography>
          <Box
            sx={{
              backgroundColor: warm[500],
              padding: 2,
              borderRadius: "4px",
              my: 3,
            }}
          >
            <Typography
              className="title"
              variant="smallCopy"
              sx={{ color: "neutral.main" }}
              gutterBottom
            >
              Current value
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="subtitle1"
                sx={{ color: "neutral.main", mr: 1 }}
              >
                ${moneyFormat(investment.marketPrice, 4)}
              </Typography>
              {investment?.dailyChange >= 0 ? (
                <ArrowUpIcon />
              ) : (
                <SouthEastIcon fontSize="small" />
              )}

              <Typography
                variant="smallCopyBold"
                sx={{ color: "neutral.main" }}
              >
                {investment?.dailyChange >= 0
                  ? `+${moneyFormat(investment?.dailyChange, 2)}`
                  : moneyFormat(investment?.dailyChange, 2)}
                %
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Paper
                variant="outlined"
                sx={{ paddingY: 6, paddingX: 4, border: "none" }}
              >
                <Stack spacing={3}>
                  {type === INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER && (
                    <Grid sx={{ mt: 2 }} item xs={12} sm={3.8}>
                      <SelectedFundDropDown
                        setSelectedFund={setSelectedFundAccount}
                      />
                    </Grid>
                  )}
                  <Box>
                    <Typography
                      className="subtitle3"
                      variant="subtitle3"
                      gutterBottom
                    >
                      {type !== INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER
                        ? `Enter amount`
                        : `Transfer Amount`}
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container alignItems="center" spacing={2}>
                      {enterUnits ? (
                        <>
                          <Grid item xs={11} sm={4}>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              placeholder="Units"
                              value={
                                requestedUnits
                                  ? moneyFormat(requestedUnits, 4, 0)
                                  : ""
                              }
                              onChange={(e) =>
                                handleRequestedUnitsChange(
                                  parseFloat(e.target.value)
                                )
                              }
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>
                          <Grid item xs={1} textAlign={"center"}>
                            <Typography
                              sx={{ color: "neutral.main" }}
                              variant="subtitle3"
                              gutterBottom
                            >
                              units
                            </Typography>
                          </Grid>
                          {type !== INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER && (
                            <>
                              <Grid item xs={2} sm={1}>
                                <IconButton
                                  onClick={() => setEnterUnits(!enterUnits)}
                                  sx={{
                                    backgroundColor: primary[500],
                                    color: "#fff",
                                    "&:hover": {
                                      backgroundColor: primary[800],
                                    },
                                  }}
                                >
                                  <SyncAltIcon />
                                </IconButton>
                              </Grid>

                              <Grid item xs={10} sm={3} textAlign={"start"}>
                                <Typography
                                  variant="subtitle3"
                                  color={neutral[900]}
                                  gutterBottom
                                >
                                  $
                                  {requestedAmount
                                    ? moneyFormat(requestedAmount, 2, 2)
                                    : 0}
                                  *
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Grid item xs={1} textAlign={"center"}>
                            <Typography
                              sx={{ color: "neutral.main" }}
                              variant="subtitle3"
                              gutterBottom
                            >
                              $
                            </Typography>
                          </Grid>
                          <Grid item xs={11} sm={4}>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              placeholder={"Amount"}
                              value={
                                requestedAmount
                                  ? moneyFormat(requestedAmount, 2, 0)
                                  : ""
                              }
                              onChange={(e) =>
                                handleRequestedAmountChange(
                                  parseFloat(e.target.value)
                                )
                              }
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            />
                          </Grid>

                          {type !== INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER && (
                            <Grid item xs={2} sm={1}>
                              <IconButton
                                onClick={() => setEnterUnits(!enterUnits)}
                                sx={{
                                  backgroundColor: primary[500],
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: primary[800],
                                  },
                                }}
                              >
                                <SyncAltIcon />
                              </IconButton>
                            </Grid>
                          )}
                          <Grid item xs={10} sm={3} textAlign={"start"}>
                            <Typography
                              variant="subtitle3"
                              color={neutral[900]}
                              gutterBottom
                            >
                              {moneyFormat(requestedUnits, 4) || 0} * units
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={3}>
                        <Typography
                          sx={{ color: neutral[600], mb: 0 }}
                          className="title"
                          variant="smallCopy"
                          paragraph
                        >
                          {type === INVESTMENT_REQUEST_TYPE_ENUM.BUY
                            ? "Available balance"
                            : type === INVESTMENT_REQUEST_TYPE_ENUM.SELL
                            ? "Available for sale"
                            : "Available for transfer"}
                        </Typography>
                        {Object.values([
                          INVESTMENT_REQUEST_TYPE_ENUM.BUY,
                          INVESTMENT_REQUEST_TYPE_ENUM.SELL,
                        ]).includes(type) && (
                          <Typography
                            variant="subtitle3"
                            sx={{ color: "neutral.main" }}
                          >
                            $
                            {moneyFormat(
                              type === INVESTMENT_REQUEST_TYPE_ENUM.BUY
                                ? selectedAccount?.availableBalance || 0
                                : selectedFundInvestment?.totalApprovedUnits! *
                                    selectedFundInvestment?.investment
                                      ?.marketPrice!
                            )}
                          </Typography>
                        )}
                        {Object.values([
                          INVESTMENT_REQUEST_TYPE_ENUM.SELL,
                          INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER,
                        ]).includes(type) && (
                          <Typography
                            sx={{ color: "neutral.main", mb: 0 }}
                            variant="smallCopyBold"
                            paragraph
                          >
                            {moneyFormat(
                              selectedFundInvestment?.totalApprovedUnits || 0,
                              4
                            )}{" "}
                            units
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  {!INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER ? (
                    <Box>
                      {enterUnits ? (
                        <>
                          {requestedUnits !== 0 && (
                            <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <ErrorIcon
                                  sx={{ mr: 1 }}
                                  fontSize="small"
                                  color="primary"
                                />

                                <Typography
                                  variant="bodyCopyRegular"
                                  sx={{ color: "neutral.main" }}
                                >
                                  *Cash value is an estimate, and will be
                                  confirmed once the recommendation is processed
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <>
                          {requestedAmount !== 0 && (
                            <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <ErrorIcon
                                  sx={{ mr: 1 }}
                                  fontSize="small"
                                  color="primary"
                                />

                                <Typography
                                  variant="bodyCopyRegular"
                                  sx={{ color: "neutral.main" }}
                                >
                                  *Amounts are estimated only and will be
                                  confirmed once the recommendation is
                                  processed.{" "}
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                        </>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      {checkBalance() && (
                        <FormHelperText>
                          {" "}
                          <ErrorIcon /> You are able to transfer only{" "}
                          {selectedFundInvestment?.totalApprovedUnits}
                        </FormHelperText>
                      )}
                    </Box>
                  )}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={2}>
                <Paper
                  variant="outlined"
                  sx={{ paddingY: 5, paddingX: 4, border: "none" }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "neutral.main" }}
                      gutterBottom
                    >
                      Summary
                    </Typography>
                    <Typography
                      variant="bodyCopyRegular"
                      sx={{ color: "neutral.main" }}
                    >
                      {selectedAccount?.fundAccountName}
                    </Typography>
                  </Box>
                  {!!requestedAmount && !!requestedUnits && (
                    <>
                      <Box marginTop={3}>
                        <Typography
                          variant="smallCopy"
                          sx={{ color: "neutral.main", mb: 1 }}
                          className="title"
                          paragraph
                        >
                          {type !== INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER
                            ? `${investment.ticker} value`
                            : `Transferring to`}
                        </Typography>
                        {type !== INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="subtitle3"
                              sx={{ color: "neutral.main", mr: 1 }}
                            >
                              ${moneyFormat(investment.marketPrice, 4)}
                            </Typography>
                            {investment?.dailyChange >= 0 ? (
                              <ArrowUpIcon />
                            ) : (
                              <SouthEastIcon fontSize="small" />
                            )}

                            <Typography
                              variant="smallCopyBold"
                              sx={{ color: "neutral.main" }}
                            >
                              {investment?.dailyChange >= 0
                                ? `+${moneyFormat(investment?.dailyChange, 2)}`
                                : moneyFormat(investment?.dailyChange, 2)}
                              %
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              variant="smallCopyBold"
                              sx={{ color: "neutral.main" }}
                            >
                              {selectedFund?.fundName}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Divider
                        sx={{
                          marginY: 2,
                          backgroundColor: warm[600],
                          borderWidth: "1px",
                        }}
                      />
                      <Box marginTop={3}>
                        <Typography
                          variant="smallCopy"
                          sx={{ color: "neutral.main", mb: 1 }}
                          className="title"
                          paragraph
                        >
                          {type === INVESTMENT_REQUEST_TYPE_ENUM.BUY
                            ? "Buying"
                            : type === INVESTMENT_REQUEST_TYPE_ENUM.SELL
                            ? "Selling"
                            : "Amount"}
                        </Typography>
                        <Typography
                          variant="subtitle3"
                          sx={{ color: "neutral.main", mb: 0 }}
                          className="title"
                          paragraph
                        >
                          {moneyFormat(requestedUnits, 4)}
                          {!enterUnits && "* "} {investment.ticker}
                        </Typography>
                        {!enterUnits && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 1,
                            }}
                          >
                            <ErrorIcon
                              sx={{
                                color: neutral[500],
                                fontSize: "12px",
                                mr: 1,
                              }}
                            />
                            <Typography
                              variant="smallCopy"
                              sx={{ color: neutral[500] }}
                              className="title"
                            >
                              Estimated
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {type !== INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER && (
                        <>
                          <Divider
                            sx={{
                              marginY: 2,
                              backgroundColor: warm[600],
                              borderWidth: "1px",
                            }}
                          />

                          <Box>
                            <Typography
                              variant="smallCopy"
                              sx={{ color: "neutral.main", mb: 1 }}
                              className="title"
                            >
                              Total amount
                            </Typography>
                            <Typography
                              sx={{ color: "primary.main" }}
                              variant="subtitle1"
                            >
                              {" "}
                              {type === INVESTMENT_REQUEST_TYPE_ENUM.BUY &&
                                "-"}{" "}
                              ${moneyFormat(requestedAmount)}
                              {enterUnits && "* "}
                            </Typography>
                            {enterUnits && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mt: 1,
                                }}
                              >
                                <ErrorIcon
                                  sx={{
                                    color: neutral[500],
                                    fontSize: "12px",
                                    mr: 1,
                                  }}
                                />
                                <Typography
                                  variant="smallCopy"
                                  sx={{ color: neutral[500] }}
                                  className="title"
                                >
                                  Estimated
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </Paper>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={
                    isLoading && <CircularProgress size={20} color="inherit" />
                  }
                  disabled={
                    isLoading ||
                    !requestedAmount ||
                    !requestedUnits ||
                    isMakingTransferLoading ||
                    checkBalance()
                  }
                  onClick={(event) =>
                    type !== INVESTMENT_REQUEST_TYPE_ENUM.TRANSFER
                      ? confirm(event)
                      : onConfirmTransfer()
                  }
                >
                  Confirm
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
