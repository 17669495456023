import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { neutral } from "style/color";
import AddIcon from "@mui/icons-material/Add";

import BalanceBox from "components/shared/balance-box";
import { InvestmentRequestAssetCard } from "../../components/investment/investment-request/investment-request-asset-card";
import { useNavigate } from "react-router-dom";
import { InvestmentsHomePagePath } from "constants/pages";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { FundInvestment } from "types/fund-investment";
import {
  useGetFundInvestmentRequestsQuery,
  useGetFundInvestmentTotalQuery,
} from "hooks/use-services/investment-request.service";
import { LoadingCard } from "components/loader/loading-card";
import { AuthData } from "types/auth";
import { AuthContext } from "providers/userProvider";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";
interface InvestmentRequestsPageProps {}

export const InvestmentRequestsPage: React.FC<
  InvestmentRequestsPageProps
> = () => {
  const navigate = useNavigate();
  const { selectedAccount, userData, userRole } =
    useContext<AuthData>(AuthContext);
  const [id, setID] = React.useState<any>(0);
  const [hideZeroAmount, setHideZeroAmount] = useState(false);

  useEffect(() => {
    if (selectedAccount) {
      if (selectedAccount) {
        setID(selectedAccount.fundAccountId);
      }
    }
  }, [selectedAccount]);
  const {
    data,
    fetchNextPage,
    isSuccess,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetFundInvestmentRequestsQuery(id, {
    limit: 10,
  });
  const { data: fundInvestmentTotal } = useGetFundInvestmentTotalQuery({
    fundId: (selectedAccount?.fundAccountId as number) || 0,
  });
  const investmentsRequestsData = useMemo<FundInvestment[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );

  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });

  return (
    <Layout>
      {() => (
        <Box sx={{ my: 4, width: "100%" }}>
          <BalanceBox
            name={"My investments"}
            children={
              <Button
                onClick={() => navigate(InvestmentsHomePagePath)}
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  userRole === USER_ROLE.VIEWER ||
                  userData?.user?.status === USER_STATUS.PENDING
                }
              >
                Buy investment
              </Button>
            }
            total={
              fundInvestmentTotal?.totalAmount
                ? fundInvestmentTotal?.totalAmount
                : 0
            }
          />

          <Grid sx={{ pt: 5, px: 4 }}>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: neutral[900],
                }}
              >
                MY ASSETS
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hideZeroAmount}
                    onChange={(e) => setHideZeroAmount(e.target.checked)}
                    color="primary"
                  />
                }
                label="Hide investments with zero units"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: neutral[500],
                  },
                }}
              />
            </Grid>
            {isLoading && <LoadingCard nbElement={3} color={"white"} />}
            <Grid xs={12} sm={4}>
              {isSuccess && (
                <>
                  {investmentsRequestsData.length > 0 ? (
                    investmentsRequestsData
                      .filter(
                        (request) =>
                          !hideZeroAmount || request.totalApprovedUnits !== 0
                      )
                      .map((request) => (
                        <InvestmentRequestAssetCard
                          role={userRole!}
                          userStatus={userData?.user?.status! as USER_STATUS}
                          name={request.investment.name}
                          ticker={request.investment.ticker}
                          marketPrice={request.investment.marketPrice}
                          dailyChange={request.investment.dailyChange}
                          totalApprovedUnits={request.totalApprovedUnits}
                          totalPendingUnits={request.totalPendingUnits}
                          isAFP={request.investment.isAFP}
                        />
                      ))
                  ) : (
                    <Typography variant="subtitle3">
                      No investment request found.
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            <Box ref={lastElementRef}>
              {isFetchingNextPage && (
                <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                  {" "}
                  <CircularProgress color="primary" />
                </Grid>
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Layout>
  );
};
