import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { CssBaseline } from "@mui/material";
import { theme } from "./style/theme";
import { Authenticator } from "@aws-amplify/ui-react";
import AppRouter from "./routing/Router";
import axios from "axios";
import UserContextProvider from "./providers/userProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { fetchAuthSession } from "aws-amplify/auth";
import NetaSettingContextProvider from "providers/netaSettingsProvider";
import { translations } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage("en", {
  "Password must have lower case letters": "Password must have at least one lower case letter",
  "Password must have upper case letters": "Password must have at least one upper case letter",
  "Password must have numbers": "Password must have at least one number",
  "Password must have special characters": "Password must have at least one special character",
  "An account with the given email already exists.": "An account with the given email already exists. Please try to sign in, or click the Forgot Your Password link to re-set your password",
  "We Emailed You": "We emailed you",
  "Your code is on the way. To log in, enter the code we emailed to": "We want to verify that you have access to the email address you've entered:",
  "It may take a minute to arrive": "Please check your inbox for the code we sent you and enter it below. Please note it may take a few minutes to arrive, or may end up in your spam folder",
  "Confirm TOTP Code": "Confirm two-Factor Authentication Code",
});

// Set language
I18n.setLanguage("en");
export const api = axios.create({
  baseURL: awsExports.endpoints[0].endpoint,
});

// TODO:: refactor axios client
api.interceptors.request.use(async (reqConfig) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  reqConfig.headers.Authorization = `Bearer ${idToken?.toString() as string}`;
  return reqConfig;
});

const queryClient = new QueryClient({});

function App() {
  return (
    <div className="App">
      {/* Mui provider */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Amplify provider */}
        <Authenticator.Provider>
          {/* Routing */}

          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <NetaSettingContextProvider>
                <UserContextProvider api={api}>
                  <AppRouter />
                </UserContextProvider>
              </NetaSettingContextProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </ThemeProvider>
    </div>
  );
}
export default App;
