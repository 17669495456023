import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home } from "../pages/homepage/home";
import {
  AddMoneyPage,
  AddSuccessorPage,
  AddFundPage,
  CharitiesPagePath,
  CharitiesSearchPagePath,
  ContributionReceiptsPage,
  EditContributionReceiptsPage,
  EditSuccessorPage,
  EntitySettingsPage,
  FundDashboardPage,
  FundSettingsPage,
  GrantVestmentPage,
  GrantVestmentRequestPageUrl,
  GrantVestmentsHomePage,
  GrantVestmentsSearchPage,
  HomePage,
  InterestsSettingsPage,
  InvestmentsHomePagePath,
  MyGrantVestmentDetailsPage,
  MyGrantVestmentRequestPage,
  SignUpPage,
  SuccessionPlanPage,
  UserSettingsPage,
  InvestmentsBuyRequestPagePath,
  InvestmentsSellRequestPagePath,
  MyInvestmentsRequestDetailPage,
  MyInvestmentsRequestsPage,
  CharityGrantRequestPagePath,
  MyGrantsRequestsPagePath,
  ContributionTransactionDetailPageUrl,
  GrantVestmentRequestDetailPageUrl,
  MyInvestmentsRequestTransactionDetailPage,
  MyGrantsRequestDetailPagePath,
  MyGrantsRequestEditPagePath,
  MyFundsPageUrl,
  MyCashPageUrl,
  MyGrantsTransactionDetailPagePath,
  RolesPermissionsPagePath,
  InviteUserPagePath,
  ChangeUserRolePagePath,
  SignUpByInvitePage,
  EmailSettingsPage,
  MyFeeDetailPage,
  MyDividendDistributionDetailPage,
  InvestmentsTransferTransactionPagePath,
  GrantVestmentTransferTransactionPageUrl,
  CashTransferUrl,
  CashTransferDetailPageUrl,
  InvestmentTransferDetailPageUrl,
  GrantVestmentTransferDetailPageUrl,
  FundAdjustmentDetailsPage,
  GetHelpPageUrl,
} from "../constants/pages";
import GrantVestmentsHome from "../pages/grant-vestments/grant-vestments-home";
import GrantVestmentsSearch from "../pages/grant-vestments/grantvestments-search";
import { Grantvestment } from "../pages/grant-vestments/grant-vestment";
import { AddSuccessor } from "pages/fund-settings/succession-plan/add-successor";
import { EditSuccessor } from "pages/fund-settings/succession-plan/edit-successor";
import { SuccessionPlan } from "pages/fund-settings/succession-plan/succession-plan";
import { EntitySettings } from "pages/fund-settings/entity-settings";
import { InterestsSettings } from "pages/fund-settings/interests-settings";
import { ContributionReceipts } from "pages/contribution-transaction/contribution-receipts";
import { Dashboard } from "pages/fund-dashboard";
import { EditContributionTransaction } from "pages/contribution-transaction/edit-contribution-transaction";
import GrantVestmentRequestPage from "pages/grant-vestments/grant-vestment-request";
import { AddMoney } from "pages/contribution-transaction/add-money/add-money";
import { FundSettings } from "pages/fund-settings/fund-settings";
import CharitiesPage from "pages/charities/charities-page";
import FundBackedGrantVestmentList from "pages/fund-backed-grant-vestment/fund-backed-grant-vestment-list";
import { FundBackedGrantVestmentDetails } from "pages/fund-backed-grant-vestment/fund-backed-grant-vestment-details";
import CharitiesSearchPage from "pages/charities/charities-search-page";
import { InvestmentsPage } from "pages/investments";
import InvestmentBuyRequestPage from "pages/investments/investment-buy-request";
import InvestmentSellRequestPage from "pages/investments/investment-sell-request";
import { InvestmentRequestDetails } from "pages/investments/investment-request-details/investment-request-details";
import { InvestmentRequestsPage } from "pages/investments/investment-requests-home";
import GrantRequestHomePage from "pages/charities/grant-request/grant-request-home";
import { MyGrantsRequestPage } from "pages/charities/my-grants/my-grants-request-page";
import { ContributionTransactionDetailsPage } from "pages/contribution-transaction/contribution-transaction-details";
import { GrantVestmentDetailsPage } from "pages/fund-backed-grant-vestment/grantvestment-request-details";
import { InvestmentTransactionDetailsPage } from "pages/investments/investment-request-details/investment-transaction-details";
import { GrantTransactionDetailsPage } from "pages/charities/my-grants/grant-transaction-details";
import { GrantTransactionEditPage } from "pages/charities/my-grants/grant-transaction-edit";
import { MyFundsPage } from "pages/my-funds/my-funds";
import { MyCashPage } from "pages/my-cash/my-cash";
import { MainGrantTransactionDetailsPage } from "pages/charities/my-grants/main-grant-transaction-details";
import { PolicyPage } from "pages/policy";
import { AddFund } from "pages/fund-settings/add-fund";
import { RolesPermissionsPage } from "pages/fund-settings/roles-permissions/roles-permissions";
import { InviteUserPage } from "pages/fund-settings/roles-permissions/invite-user";
import { ChangeUserRolePage } from "pages/fund-settings/roles-permissions/change-user-role";
import { SignUp } from "pages/signup/user-with-fund/signup";
import { PersonalSettings } from "pages/user-settings/personal-settings";
import { SignUpByInvite } from "pages/signup/user-by-invite/signup-by-invite";
import PrivateRoute from "./PrivateRouter";
import { USER_ROLE } from "types/account";
import { EmailSettings } from "pages/fund-settings/email-settings";
import { AuthPage } from "pages/auth";
import { AgreementPolicyPage } from "pages/agreement-policy";
import { MyFeeTransactionDetailsPage } from "pages/fund-fee/fee-details-page";
import { MyDividendDistributionTransactionDetailsPage } from "pages/fund-dividend-distribution/dividend-distribution-details-page";
import InvestmentTransferTransactionPage from "pages/investments/investment-transfer-transaction";
import GrantVestmentTransferTransactionPage from "pages/grant-vestments/grant-vestment-transfer-transaction";
import CashTransferTransactionPage from "pages/my-cash/cash-transfer-transaction";
import { TransferTransactionDetailsPage } from "pages/transfer/transfer-detail";
import { AdjustmentDetailsPage } from "pages/adjustments/adjustment-details-page";
import { GetHelpPage } from "pages/get-help";

const AppRouter = () => {
  const { pathname } = useLocation();
  const allUsers = [USER_ROLE.VIEWER, USER_ROLE.ADVISOR, USER_ROLE.ADMIN];
  const advisorAndAdmin = [USER_ROLE.ADVISOR, USER_ROLE.ADMIN];
  const adminOnly = [USER_ROLE.ADMIN];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      {/* public pages */}
      <Route path="/policy" Component={PolicyPage} />
      <Route path="/signIn" Component={AuthPage} />
      <Route path="/agreement-policies" Component={AgreementPolicyPage} />
      <Route path={SignUpPage} Component={SignUp} />
      <Route path={SignUpByInvitePage} Component={SignUpByInvite} />
      <Route
        path={HomePage}
        element={
          <PrivateRoute element={<Home />} pending={true} roles={allUsers} />
        }
      />
      <Route
        path={GrantVestmentsHomePage}
        element={
          <PrivateRoute
            element={<GrantVestmentsHome />}
            pending={true}
            roles={allUsers}
          />
        }
      />
      <Route
        path={GrantVestmentsSearchPage}
        element={
          <PrivateRoute
            element={<GrantVestmentsSearch />}
            pending={true}
            roles={allUsers}
          />
        }
      />
      <Route
        path={GrantVestmentPage}
        element={
          <PrivateRoute
            element={<Grantvestment />}
            pending={true}
            roles={allUsers}
          />
        }
      />
      <Route
        path={CharitiesPagePath}
        element={
          <PrivateRoute
            element={<CharitiesPage />}
            pending={true}
            roles={allUsers}
          />
        }
      />
      <Route
        path={CharitiesSearchPagePath}
        element={
          <PrivateRoute
            element={<CharitiesSearchPage />}
            pending={true}
            roles={allUsers}
          />
        }
      />
      {/* public pages */}
      <Route
        path={UserSettingsPage}
        element={
          <PrivateRoute
            element={<PersonalSettings />}
            pending={true}
            roles={allUsers}
          />
        }
      />
      <Route
        path={AddSuccessorPage}
        element={<PrivateRoute element={<AddSuccessor />} roles={adminOnly} />}
      />
      <Route
        path={EditSuccessorPage}
        element={<PrivateRoute element={<EditSuccessor />} roles={adminOnly} />}
      />
      <Route
        path={SuccessionPlanPage}
        element={<PrivateRoute element={<SuccessionPlan />} roles={allUsers} />}
      />
      <Route
        path={RolesPermissionsPagePath}
        element={
          <PrivateRoute element={<RolesPermissionsPage />} roles={adminOnly} />
        }
      />
      <Route
        path={InviteUserPagePath}
        element={
          <PrivateRoute element={<InviteUserPage />} roles={adminOnly} />
        }
      />
      <Route
        path={ChangeUserRolePagePath}
        element={
          <PrivateRoute element={<ChangeUserRolePage />} roles={adminOnly} />
        }
      />
      <Route
        path={AddFundPage}
        element={<PrivateRoute element={<AddFund />} roles={allUsers} />}
      />
      <Route
        path={MyFundsPageUrl}
        element={<PrivateRoute element={<MyFundsPage />} roles={allUsers} />}
      />
      <Route
        path={FundSettingsPage}
        element={<PrivateRoute element={<FundSettings />} roles={allUsers} />}
      />
      <Route
        path={EntitySettingsPage}
        element={<PrivateRoute element={<EntitySettings />} roles={allUsers} />}
      />
      <Route
        path={InterestsSettingsPage}
        element={
          <PrivateRoute element={<InterestsSettings />} roles={allUsers} />
        }
      />
      <Route
        path={EmailSettingsPage}
        element={<PrivateRoute element={<EmailSettings />} roles={allUsers} />}
      />
      <Route
        path={ContributionReceiptsPage}
        element={
          <PrivateRoute element={<ContributionReceipts />} roles={allUsers} />
        }
      />
      <Route
        path={ContributionTransactionDetailPageUrl}
        element={
          <PrivateRoute
            element={<ContributionTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={FundDashboardPage}
        element={<PrivateRoute element={<Dashboard />} roles={allUsers} />}
      />
      <Route
        path={AddMoneyPage}
        element={
          <PrivateRoute element={<AddMoney />} roles={advisorAndAdmin} />
        }
      />
      <Route
        path={EditContributionReceiptsPage}
        element={
          <PrivateRoute
            element={<EditContributionTransaction />}
            roles={advisorAndAdmin}
          />
        }
      />
      <Route
        path={GrantVestmentRequestPageUrl}
        element={
          <PrivateRoute
            element={<GrantVestmentRequestPage />}
            roles={advisorAndAdmin}
          />
        }
      />
      <Route
        path={GrantVestmentRequestDetailPageUrl}
        element={
          <PrivateRoute
            element={<GrantVestmentDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={CharityGrantRequestPagePath}
        element={
          <PrivateRoute
            element={<GrantRequestHomePage />}
            roles={advisorAndAdmin}
          />
        }
      />
      <Route
        path={MyGrantVestmentRequestPage}
        element={
          <PrivateRoute
            element={<FundBackedGrantVestmentList />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={MyGrantVestmentDetailsPage}
        element={
          <PrivateRoute
            element={<FundBackedGrantVestmentDetails />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={InvestmentsHomePagePath}
        element={
          <PrivateRoute
            element={<InvestmentsPage />}
            pending={true}
            roles={allUsers}
          />
        }
      />
      <Route
        path={InvestmentsBuyRequestPagePath}
        element={
          <PrivateRoute
            element={<InvestmentBuyRequestPage />}
            roles={advisorAndAdmin}
          />
        }
      />
      <Route
        path={InvestmentsTransferTransactionPagePath}
        element={
          <PrivateRoute
            element={<InvestmentTransferTransactionPage />}
            roles={adminOnly}
          />
        }
      />
      <Route
        path={InvestmentsSellRequestPagePath}
        element={
          <PrivateRoute
            element={<InvestmentSellRequestPage />}
            roles={advisorAndAdmin}
          />
        }
      />
      <Route
        path={MyInvestmentsRequestDetailPage}
        element={
          <PrivateRoute
            element={<InvestmentRequestDetails />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={MyInvestmentsRequestTransactionDetailPage}
        element={
          <PrivateRoute
            element={<InvestmentTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={InvestmentTransferDetailPageUrl}
        element={
          <PrivateRoute
            element={<TransferTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={GrantVestmentTransferDetailPageUrl}
        element={
          <PrivateRoute
            element={<TransferTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={MyInvestmentsRequestsPage}
        element={
          <PrivateRoute element={<InvestmentRequestsPage />} roles={allUsers} />
        }
      />
      <Route
        path={MyGrantsRequestsPagePath}
        element={
          <PrivateRoute element={<MyGrantsRequestPage />} roles={allUsers} />
        }
      />
      <Route
        path={MyGrantsRequestDetailPagePath}
        element={
          <PrivateRoute
            element={<MainGrantTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={MyGrantsTransactionDetailPagePath}
        element={
          <PrivateRoute
            element={<GrantTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={MyGrantsRequestEditPagePath}
        element={
          <PrivateRoute
            element={<GrantTransactionEditPage />}
            roles={advisorAndAdmin}
          />
        }
      />
      <Route
        path={GrantVestmentTransferTransactionPageUrl}
        element={
          <PrivateRoute
            element={<GrantVestmentTransferTransactionPage />}
            roles={adminOnly}
          />
        }
      />
      <Route
        path={MyCashPageUrl}
        element={<PrivateRoute element={<MyCashPage />} roles={allUsers} />}
      />{" "}
      <Route
        path={FundAdjustmentDetailsPage}
        element={
          <PrivateRoute element={<AdjustmentDetailsPage />} roles={allUsers} />
        }
      />{" "}
      <Route
        path={CashTransferUrl}
        element={
          <PrivateRoute
            element={<CashTransferTransactionPage />}
            roles={adminOnly}
          />
        }
      />
      <Route
        path={MyFeeDetailPage}
        element={
          <PrivateRoute
            element={<MyFeeTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={MyDividendDistributionDetailPage}
        element={
          <PrivateRoute
            element={<MyDividendDistributionTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={CashTransferDetailPageUrl}
        element={
          <PrivateRoute
            element={<TransferTransactionDetailsPage />}
            roles={allUsers}
          />
        }
      />
      <Route
        path={GetHelpPageUrl}
        element={<PrivateRoute element={<GetHelpPage />} roles={allUsers} />}
      />{" "}
    </Routes>
  );
};
export default AppRouter;
