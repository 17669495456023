import { Paper, Typography } from "@mui/material";
import { moneyFormat, unitFormat } from "utils/money-format";

export const ThankYou4YourTransfer = ({
  amount,
  type,
  from,
  to,
  name,
}: {
  amount: number;
  type: "GRANTVESTMENT" | "INVESTMENT" | "CASH";
  from: string;
  to: string;
  // could be investment ticker or grantVestment name
  name?: string;
}) => {
  return (
    <Paper variant="outlined" sx={{ paddingY: 5, paddingX: 4, border: "none" }}>
      <Typography variant="h3" sx={{ color: "neutral.main" }} gutterBottom>
        Thank you!
      </Typography>
      <Typography variant="bodyCopyRegular">
        We received your transfer request for{" "}
      </Typography>
      <Typography variant="bodyCopyBold" sx={{ color: "neutral.main" }}>
        {type !== "INVESTMENT" ? "$" : " "}
        {type !== "INVESTMENT" ? moneyFormat(amount) : unitFormat(amount)}
        {type === "INVESTMENT"
          ? ` units of ${name} `
          : type === "GRANTVESTMENT"
          ? ` of GrantVestment ${name} `
          : " "}
      </Typography>
      <Typography variant="bodyCopyRegular">from </Typography>{" "}
      <Typography variant="bodyCopyBold"> {from}</Typography>{" "}
      <Typography variant="bodyCopyRegular">to </Typography>{" "}
      <Typography variant="bodyCopyBold"> {to}.</Typography>
      <br />
      <Typography variant="bodyCopyRegular">
        Our team will review this request as soon as possible.
        <br />
        Once approved, this will be reflected in the impacted funds.
      </Typography>
    </Paper>
  );
};
