import { getAdjustmentAPI } from "api/fund-adjustment.api";
import { useQuery } from "react-query";
import { Adjustment } from "types/adjustment";

const ADJUSTMENT_RQ_KEY = "fund-adjustments";
export const useGetAdjustmentQuery = (adjustmentId: number) => {
  return useQuery<Adjustment>({
    queryKey: [ADJUSTMENT_RQ_KEY, adjustmentId],
    queryFn: () => getAdjustmentAPI(adjustmentId),
  });
};
