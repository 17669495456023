import { GRANTVESTMENT_STATUS } from "types/grant-vestment";

export const GRANT_VESTMENT = {
  grantVestmentId: 0,
  name: "",
  description: "",
  about: "",
  projectBy: "",
  deadline: new Date(),
  goalAmount: 0,
  raisedAmount: 0,
  allOrNothing: true,
  category: {
    categoryId: 0,
    categoryName: "",
  },
  imageUrl: "",
  grantVestorCounter: 0,
  status: GRANTVESTMENT_STATUS.RAISING,
  grantAuthorizedViewers: [],
};
