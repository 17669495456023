import React from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import Layout from "components/layout/layout";
import ConstructionIcon from "@mui/icons-material/Construction";

export const GetHelpPage = () => {
  return (
    <Layout>
      {() => (
        <>
          <Box
            sx={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.default",
            }}
          >
            <Container maxWidth="sm">
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  textAlign: "center",
                  borderRadius: 2,
                  bgcolor: "background.paper",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    mb: 4,
                  }}
                >
                  <ConstructionIcon
                    sx={{
                      fontSize: 120,
                      color: "primary.main",
                      animation: "pulse 2s infinite ease-in-out",
                    }}
                  />
                </Box>

                <Typography
                  variant="h3"
                  component="h1"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    mb: 2,
                  }}
                >
                  Under Construction
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    color: "text.secondary",
                    mb: 1,
                  }}
                >
                  We are currently working on developing a comprehensive help
                  page.
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  In the meantime, please reach out to us via email or phone and
                  we will help with whatever you need.
                </Typography>
              </Paper>
            </Container>

            <style>
              {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.1); }
            100% { transform: scale(1); }
          }
        `}
            </style>
          </Box>
        </>
      )}
    </Layout>
  );
};
