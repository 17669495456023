import { Account } from "./account";
import { User } from "./user";
export enum FUND_ADJUSTMENT_VALUE_TYPE_ENUM {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}
export enum FUND_MOVEMENT_TYPE_ENUM {
  CONTRIBUTION = "CONTRIBUTION",
  INVESTMENT = "INVESTMENT",
  GRANT = "GRANT",
  GRANTVESTMENT = "GRANTVESTMENT",
  DISTRIBUTION = "DISTRIBUTION",
  FEE = "FEE",
  ADJUSTMENT = "ADJUSTMENT",
  TRANSFER = "TRANSFER",
}

export interface Adjustment {
  fundAdjustmentId: number;
  fundAccount: Account;
  adjustmentType: FUND_MOVEMENT_TYPE_ENUM;
  valueType: FUND_ADJUSTMENT_VALUE_TYPE_ENUM;
  description: string;
  executionDate: Date;
  amount: number;
  user: User;
}
