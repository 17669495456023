import {
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { AuthContext } from "providers/userProvider";
import { useContext, useState, useEffect, useMemo } from "react";
import { USER_ROLE } from "types/account";
import { AuthData } from "types/auth";
import { FundAccountMember } from "types/fund-account-member";

interface Props {
  setSelectedFund: (fund: any) => void; // Function to update the selected fund
}
export const SelectedFundDropDown = ({ setSelectedFund }: Props) => {
  const { userData, selectedAccount } = useContext<AuthData>(AuthContext);
  const [fundAccountMembers, setFundAccounts] = useState<FundAccountMember[]>(
    []
  );
  useEffect(() => {
    if (userData?.user && selectedAccount) {
      setFundAccounts(userData?.user?.fundAccounts!);
    }
  }, [userData?.user, selectedAccount]);

  const accounts = useMemo(
    () =>
      fundAccountMembers
        .filter(
          (account) =>
            account.role === USER_ROLE.ADMIN &&
            account.fund.fundAccountId !== selectedAccount?.fundAccountId
        )
        .map((account) => {
          return {
            fundId: account.fund.fundAccountId,
            fundName: account.fund.fundAccountName,
          };
        }),
    [fundAccountMembers, selectedAccount?.fundAccountId]
  );

  return (
    <FormControl fullWidth>
      <InputLabel>
        <Typography className="subtitle3" variant="subtitle3" paddingBottom={3}>
          Select Fund to transfer to
        </Typography>
      </InputLabel>
      <Select
        required
        fullWidth
        id="fund-investments-select"
        size="small"
        name="ticker"
        onChange={(event) => {
          setSelectedFund(event.target.value as any);
        }}
      >
        {accounts.length > 0 &&
          accounts.map((fund) => (
            <MenuItem key={`fund-${fund.fundId}`} value={fund as any}>
              {fund.fundName}
            </MenuItem>
          ))}
        <MenuItem sx={{ p: 0 }} value="loading"></MenuItem>
      </Select>
    </FormControl>
  );
};
